<template>
  <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
 width: 100%;
 height: 100%;
}
 html body{
 padding: 0;
  margin: 0;
}
</style>
